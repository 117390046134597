const toggler = document.querySelector('.menu-toggler');
const wrapper = document.querySelector('.menu-wrapper');
const header = document.querySelector('header');
const body = document.querySelector('body');

const cssClass = {
    show: 'show',
    menuShown: 'menu-shown',
};

/**
 * Shows menu
 */
const show = () => {
    wrapper.classList.add(cssClass.show);
    toggler.setAttribute('aria-expanded', true);
    header.classList.add(cssClass.menuShown);
    body.classList.add(cssClass.menuShown);
};

/**
 * Hides menu
 */
const hide = () => {
    wrapper.classList.remove(cssClass.show);
    toggler.setAttribute('aria-expanded', false);
    header.classList.remove(cssClass.menuShown);
    body.classList.remove(cssClass.menuShown);
};

/**
 * Handles click on menu toggler
 */
const handleClickOnToggler = () => {
    // menu is hidden, show it
    if (toggler.getAttribute('aria-expanded') === 'false') {
        show();
        return;
    }
    // hide menu
    hide();
};

/**
 * Hides shown menu when click outside
 * @param {Event} e
 */
const handleOutsideClick = (e) => {
    if (!toggler.contains(e.target) && !wrapper.contains(e.target)) hide();
};

// Event handlers
document.addEventListener('click', (e) => {
    handleOutsideClick(e);
});

toggler.addEventListener('click', handleClickOnToggler);